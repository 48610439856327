<template>
  <el-card class="car-card">
    <div class="page-title">{{ $t('dian-pu-sheng-ji-tao-can-lie-biao') }}</div>
    <div class="car-list flex-wrap">
      <el-card class="car" v-for="(item, i) in carList" :key="i">
        <div class="list">
          <img :src="logoObj[`logo${item.Level}`] || packageUrl" alt="" />
          <div class="name">{{ item.Name }}</div>
          <div class="count">
            {{ $t('ke-tui-guang-chan-pin-shu') }}
            <span class="blue">{{ item.GoodsLimit }}</span>
          </div>
          <div class="desc">
            {{
              $t(
                'quan-dian-tou-fang-qiang-dao-liu-liang-jiu-shi-zhuan-dao-bao-bei-huo-qu-liu-liang-geng-gao-xiao-chi-xu-tou-fang'
              )
            }}
          </div>
          <div class="price">
            ${{ item.UpgradeMoney }} /
            <span class="small">{{ $t('30-ri') }}</span>
          </div>
          <div class="btn">
            <el-button type="primary" @click="toBuy(item)">{{
              $t('gou-mai-tao-can')
            }}</el-button>
          </div>
        </div>
      </el-card>
    </div>

    <el-dialog
      :title="$t('shu-ru-zhi-fu-mi-ma')"
      :visible.sync="passwordDialog"
      width="400px"
      class="pay-dialog"
    >
      <div class="pay-box">
        <div class="label">{{ $t('qing-shu-ru-nin-de-zhi-fu-mi-ma') }}</div>
        <passwordKeyboard v-model="password"></passwordKeyboard>
        <div class="btn">
          <el-button
            type="primary"
            :disabled="password.length != 6"
            @click="submit"
            >{{ $t('que-ren') }}</el-button
          >
        </div>
      </div>
    </el-dialog>
  </el-card>
</template>
<script>
import { trainList, trainBuy } from '@/api/shop'
import passwordKeyboard from '@/components/password'
export default {
  components: {
    passwordKeyboard
  },
  data() {
    return {
      password: '',
      packageUrl: require('@/assets/imgs/car3.png'),
      logoObj: {
        logo1: require('@/assets/imgs/car3.png'),
        logo2: require('@/assets/imgs/car2.png'),
        logo3: require('@/assets/imgs/car1.png'),
        logo4: require('@/assets/imgs/car1.png'),
        logo5: require('@/assets/imgs/car1.png')
      },
      carList: [],
      passwordDialog: false,
      buyId: ''
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      trainList().then((res) => {
        this.carList = res.data.Items
      })
    },
    toBuy(data) {
      this.passwordDialog = true
      this.buyId = data.ID
    },
    submit() {
      trainBuy({
        ShopTrainID: this.buyId
      }).then((res) => {
        if (res.code == 0) {
          this.$message.success(this.$t('gou-mai-cheng-gong'))
          this.passwordDialog = true
          this.password = ''
        } else {
          this.$message.warning(res.msg)
        }
      })
    }
  }
}
</script>